import React, { useState } from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Form from 'js/components/form/form';
import Text from 'js/components/text/text';
import Collapse from 'js/components/collapse/collapse';
import ClickArea from 'js/components/click-area/click-area';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Panel from 'js/components/panel/panel';
import Select from 'js/components/select/select';
import CopyButton from 'js/components/button/copy-button';
import { exportToCSV } from 'js/utils';
import VastOutput from './vast-output';
import vastErrors from '../enums/vast-errors.enum';
import styles from '../report-tag.module.scss';
import NoticePanel from './notice-panel';

const Error = ({ code }) => {
    const message = {
        [vastErrors.EMPTY]:
            'It’s not possible to generate a VAST wrapper because no creative VAST tag has been provided.',
        [vastErrors.UNEXPECTED]:
            'There was an error while generating the 4D VAST wrapper. Please try again in a few moments.',
        [vastErrors.INVALID]:
            'The link that you provided is not a valid VAST document so a VAST wrapper could not be generated. Confirm your link responds with a valid VAST document and try your upload again',
    }[code];

    return (
        <Box margin={['base', 0, 0]}>
            <Alert theme="danger">
                <p>{message}</p>
            </Alert>
        </Box>
    );
};

const Instructions = () => (
    <Box margin={['large', 0, 0]}>
        <div className={styles.description}>
            <h3>
                In order for 4D to be able to provide contextual insights and
                optimizations for your video advertising, you will need to serve
                your creative using the 4D VAST wrapper.
            </h3>
            <Box margin={['base', 0, 0, 0]}>
                <ol>
                    <li>Enter your creative VAST tag into the input below.</li>
                    <li>Click the ‘Generate 4D VAST Wrapper’ button.</li>
                    <li>Use the 4D VAST wrapper as your video creative.</li>
                </ol>
            </Box>
        </div>
    </Box>
);

const VastInput = ({ value, disabled, hasError, onChange, onWrap }) => (
    <Box margin={['large', 0, 0]}>
        <Form onSubmit={() => onWrap()}>
            <Row gutter="small" alignItems="flex-end">
                <Col>
                    <Input
                        label="Your Creative VAST Tag"
                        value={value}
                        hasError={hasError}
                        onChange={(event) => onChange(event.target.value)}
                    />
                </Col>
                <Col spanSm="auto">
                    <Button type="submit" disabled={disabled}>
                        Generate 4D VAST Wrapper
                    </Button>
                </Col>
            </Row>
        </Form>
    </Box>
);

const AdditionalTracking = ({
    instructions,
    accountId,
    sourceId,
    eventOptions,
}) => {
    const [showCollapse, setShowCollapse] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(
        eventOptions[0].value,
    );

    const eventLabel = eventOptions.find(
        (o) => o.value === selectedEventId,
    ).label;

    const getEventUrl = (eventId) =>
        `https://ptingless.4d.silverbulletcloud.com/metrics/?source-id=${sourceId}&avocado-id=${accountId}&campaign-id=\${CAMPAIGN_ID}&insertion-order-id=\${INSERTION_ORDER_ID}&deal-id=\${INVENTORY_SOURCE_EXTERNAL_ID}&creative-id=\${CREATIVE_ID}&is_video=true&url=\${SOURCE_URL}&event_type=${eventId}`;

    const onDownloadAll = () => {
        const data = eventOptions.map((o) => ({
            'Event Type': o.label,
            'Event URL': getEventUrl(o.value),
        }));

        exportToCSV(data, 'event_tracking_urls.csv');
    };

    const toggleCollapse = () => {
        setShowCollapse(!showCollapse);
    };

    return (
        <Box>
            <ClickArea onClick={toggleCollapse}>
                <Row justifyContent="space-between" alignItems="center">
                    <Col>
                        <Text
                            color={['gray', 'black']}
                            size="larger"
                            weight="bold"
                        >
                            Additional Event Tracking URLs
                        </Text>
                    </Col>
                    <Col span="auto">
                        <div className={styles.icon}>
                            <FontAwesomeIcon
                                icon={
                                    showCollapse ? faChevronUp : faChevronDown
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </ClickArea>
            <Collapse isOpen={showCollapse}>
                <Box margin={['base', 0, 0, 0]}>
                    <Text size="large">
                        In place of the VAST tag, you can set some tracking URLs
                        to collect the events of the campaign. This alternative
                        is particularly useful in case the VAST tag cannot be
                        wrapped.
                    </Text>
                    <Box margin={['base', 0]}>
                        <NoticePanel text="Using both the VAST tag and the Event Tracking URLs would cause events to be counted twice!" />
                    </Box>
                    <div className={styles.description}>{instructions}</div>
                    <Box margin={['base', 0, 0, 0]}>
                        <Panel theme="secondary">
                            <Box padding="base">
                                <Text size="large" weight="bold">
                                    Your 4D Event Trackers
                                </Text>

                                <Box margin={['base', 0, 0]}>
                                    <Row gutter="small">
                                        <Col spanMd={3}>
                                            <Select
                                                width="same"
                                                options={eventOptions}
                                                selectedValues={selectedEventId}
                                                onChange={(e) =>
                                                    setSelectedEventId(e)
                                                }
                                            >
                                                {eventLabel}
                                            </Select>
                                        </Col>
                                        <Col>
                                            <Input
                                                value={getEventUrl(
                                                    selectedEventId,
                                                )}
                                                readOnly
                                            />
                                        </Col>
                                        <Col span="auto">
                                            <CopyButton
                                                value={getEventUrl(
                                                    selectedEventId,
                                                )}
                                                message={
                                                    <Text>URL Copied</Text>
                                                }
                                            >
                                                <Button theme="outline">
                                                    Copy
                                                </Button>
                                            </CopyButton>
                                        </Col>
                                        <Col span="auto">
                                            <Button
                                                onClick={() => onDownloadAll()}
                                            >
                                                Download All
                                            </Button>
                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                        </Panel>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

const VideoTab = ({
    source,
    accountId,
    loading,
    error,
    vastTag,
    wrappedVastTag,
    videoDuration,
    videoUrl,
    onVastChange,
    onVastWrap,
}) => (
    <>
        <Box padding={[0, 'large', 'large', 'large']}>
            <Instructions />
            <VastInput
                value={vastTag}
                disabled={loading}
                hasError={!!error}
                onChange={onVastChange}
                onWrap={onVastWrap}
            />
            {error && <Error code={error} />}
            <VastOutput
                source={source}
                loading={loading}
                wrappedTag={wrappedVastTag}
                videoDuration={videoDuration}
                videoUrl={videoUrl}
            />
        </Box>
        <Box
            borderTop={['base', 'solid', 'gray', 'lightest']}
            padding={['large']}
        >
            <AdditionalTracking
                instructions={source.eventTrackingHTML}
                accountId={accountId}
                sourceId={source.id}
                eventOptions={source.eventTrackingOptions}
            />
        </Box>
    </>
);

export default VideoTab;
